
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import Resetbackground from "../Common/Reset&ConfirmationCommon";
import Loading from "components/Loading";


// Constants
import APP_URL from "constants/ApplicationUrls";
import STATUS_CODES from "constants/StatusCodes";
import { API_URLS } from "constants/ApiUrl";
import { OTP_KEYS } from "../Constant";

// React Router Dom
import { useLocation } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from "react-redux";

// Antd
import { Button, Col, Row, Form } from "antd";

// Localization
import LOCALIZATION from "services/LocalizationService";

// Actions
import { login } from "store/actions/AuthAction";

// Helper
import { errorNotification, successNotification } from "helpers/Notification";

// Otp View
import OtpInput from 'react-otp-input';

function Login() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email")
    const password = queryParams.get("password")
    const authToken = queryParams.get("authToken")
    const socialPlatform = queryParams.get("socialPlatform")
    const [otp, setOtp] = useState('');

    const loading = useSelector((state) => state?.Auth?.loading);

    const onSubmit = (data) => {
        const prevState = JSON.parse(localStorage.getItem("prevState"))
        if (!!socialPlatform) {
            data["auth_token"] = authToken
            if (socialPlatform === "1") {
                dispatch(login(API_URLS.GMAIL_LOGIN, data)).then(
                    (res) => {
                        successNotification(LOCALIZATION.GMAIL_LOGIN_SUCCESSFULLY);
                        if (prevState) {
                            history.push(prevState); // Redirect to the requested page
                        } else {
                            history.push(APP_URL.USER_DASHBOARD);
                        }
                        localStorage.setItem("prevState",null)
                    },
                    (e) => {
                        const message = e?.response?.data?.detail
                            ? e?.response?.data?.detail
                            : e?.response?.status === STATUS_CODES.NOT_FOUND
                                ? LOCALIZATION.GMAIL_LOGIN_ERROR
                                : LOCALIZATION.NETWORK_ERROR;

                        errorNotification(message);
                    }
                );
            }
            else {
                dispatch(login(API_URLS.FACEBOOK_LOGIN, data)).then(
                    () => {
                        successNotification(LOCALIZATION.FACEBOOK_LOGIN_SUCCESSFULLY);
                        if (prevState) {
                            history.push(prevState); // Redirect to the requested page
                        } else {
                            history.push(APP_URL.USER_DASHBOARD);
                        }
                        localStorage.setItem("prevState",null)
                    },
                    (e) => {
                        const message = e?.response?.data?.detail
                            ? e?.response?.data?.detail
                            : e?.response?.status === STATUS_CODES.NOT_FOUND
                                ? LOCALIZATION.FACEBOOK_LOGIN_ERROR
                                : LOCALIZATION.NETWORK_ERROR;

                        errorNotification(message);
                    }
                );
            }
        }
        else {
            data["email"] = email
            data["password"] = password
            dispatch(login(API_URLS.LOGIN, data)).then(
                (res) => {
                    successNotification(LOCALIZATION.LOG_IN_SUCCESSFULLY);
                    if (prevState) {
                        history.push(prevState); // Redirect to the requested page
                    } else {
                        history.push(APP_URL.USER_DASHBOARD);
                    }
                    localStorage.setItem("prevState",null)
                },
                (e) => {
                    const message = e?.response?.data?.detail
                        ? e?.response?.data?.detail
                        : e?.response?.status === STATUS_CODES.NOT_FOUND
                            ? LOCALIZATION.INVALID_EMAIL_OR_PASSWORD
                            : LOCALIZATION.NETWORK_ERROR;
                    errorNotification(message);
                }
            );
        }
    };

    return (
        <Resetbackground>
            {loading && <Loading />}
            <div className="otp-screen-wrapper" >
                <Row >
                    <Col span={24} className="d-flex justify-center" >
                        <h3>
                            {LOCALIZATION.OTP_SCREEN_TEXT}
                        </h3>
                    </Col>
                    <Col span={24} className="d-flex justify-center" >
                        <Form onFinish={onSubmit} >

                            <Form.Item
                                validateTrigger={'onBlur'}
                                name={OTP_KEYS.FA_CODE}
                                rules={[
                                    { required: true, message: LOCALIZATION.REQUIRED }
                                ]}                                >
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle={{width:'0.5em'}}
                                    required
                                />
                            </Form.Item>

                            <Col span={24} className="d-flex justify-center" >
                                <Col sm={8} md={12} className="mr-2" >
                                    <Button type="white" onClick={() => history.goBack()} > {LOCALIZATION.BACK_TO_LOGIN} </Button>
                                </Col>
                                <Col sm={4} md={12} className="ml-2"  >
                                    <Button type="primary" htmlType="submit" > {LOCALIZATION.LOGIN} </Button>
                                </Col>

                            </Col>
                        </Form>
                    </Col>

                </Row>

            </div>
        </Resetbackground>
    );
}

export default Login;
