/*
  Application Login Page
*/

import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Helper
import {
  errorNotification,
  successNotification,
} from "helpers/Notification";
import { toBase64, parseGeneralErrorMessage } from "helpers/GeneralHelper";

// Constants
import APP_URL from "constants/ApplicationUrls";
import { API_URLS } from "constants/ApiUrl";
import { REDUX_STATES } from "constants/ReduxStates";
import STATUS_CODES from "constants/StatusCodes";
import { DATE_FORMAT } from "constants/DateFormat";

// Actions
import { postAction } from "store/actions/CRUDAction";

// Antd
import { Form, Button, Row, Col } from "antd";

// Component
import {
  Email,
  Password,
  Text,
  PhoneNumberWithCode,
  DatePicker,
  CheckboxItem
} from "../../Common/FormElements";
import Background from "../Common/BackgroundView";
import Loading from "components/Loading";
import { CREATE_USER_KEYS } from "../Constant"

// Signup logo
import signupLogo from "../../../assets/images/signup.png";

import "../style.scss";

// Localization
import LOCALIZATION from "services/LocalizationService";

// Pattern 
import { PASSWORD_VALIDATOR_8CHA_1NUM } from "constants/Patterns"


// Date fns
import { format } from "date-fns"

const { CREATE_USER, LOADING } = REDUX_STATES

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(signupLogo);
  const [imageFile, setImageFile] = useState();

  const {
    [CREATE_USER + LOADING]: loading = false
  } = useSelector((state) => state.Crud)


  const onSubmit = async (data) => {
    Object.keys(data || {})?.map((key) => {
      if (key?.includes("date")) {
        if (data[key] === "Invalid Date") {
          data[key] = null;
        }
        data[key] = !!data[key]
          ? format(new Date(data[key]), DATE_FORMAT.YEAR_MONTH_DAY)
          : null;
      }
    });
    if (!!imageFile) {
      data[CREATE_USER_KEYS.PHOTO_TYPE] = imageFile?.type.split("/")[1]
      const file = imageFile
      const convertFileToBase64 = await toBase64(file)
      data[CREATE_USER_KEYS.PROFILE_PHOTO] = convertFileToBase64
    }
    dispatch(
      postAction(API_URLS.CREATE_USER, data, { timeout: 900000 }, CREATE_USER)
    ).then(
      (res) => {
        if (!!res?.data?.qr_code) {
          history.push(APP_URL.AUTH.QR_CODE + `?qrCode=${res?.data?.qr_code}&simpleLogin=1`);
        }
        else {
          successNotification(LOCALIZATION.CREATE_USER_SUCCESSFULLY);
          history.push(APP_URL.AUTH.LOGIN);
        }
      },
      (e) => {
        const errMsg = parseGeneralErrorMessage(e.response.data);
        const message =
          e?.response?.status === STATUS_CODES.BAD_REQUEST
            ? errMsg || LOCALIZATION.CREATE_USER_ERROR
            : LOCALIZATION.INTERNAL_SERVER_ERROR_MESSAGE;

        errorNotification(message);
      }
    );
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setImageFile(event.target.files[0])

    reader.onloadend = () => {

      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  };

  return (
    <>
      {loading && <Loading />}
      <Row>
        <Col xs={24} md={10} lg={7}>
          <Background />
        </Col>
        <Col xs={24} md={14} lg={15}  className="login-wrapper">
          <h1 className="login-text ml-15 ">{LOCALIZATION.CREATE_ACCOUNT}</h1>
          <Form layout="vertical" className="ml-7" onFinish={onSubmit} requiredMark={LOCALIZATION.OPTIONAL} >
            <Row>
              <Col xs={24} md={14} lg={5} className="d-flex justify-end align-center">
                <div>
                  <img src={image} alt="logo" className="signup_profile_image" />
                  <Col className="mt-8 d-flex justify-center ">
                    <div>
                      <Button type="white" onClick={handleButtonClick}>
                        {LOCALIZATION.ADD_PROFILE_PHOTO}

                      </Button>
                      <Form.Item
                        name={CREATE_USER_KEYS.PROFILE_PHOTO}
                      >
                        <input
                          id="image-input"
                          type="file"
                          ref={fileInputRef}
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </div>
              </Col>

              <Col xs={24} md={14} lg={19}>
                <Col xs={24} md={20} lg={15} className="ml-8">
                  <Text
                    label={LOCALIZATION.FAMILY_NAME}
                    placeholder={LOCALIZATION.ENTER_YOUR_FAMILY_NAME}
                    name={CREATE_USER_KEYS.FAMILY_NAME}
                    required
                  />
                  <Text
                    label={LOCALIZATION.FIRST_NAME}
                    placeholder={LOCALIZATION.ENTER_YOUR_FIRST_NAME}
                    name={CREATE_USER_KEYS.FIRST_NAME}
                    required
                  />
                  <Text
                    label={LOCALIZATION.PREFERRED_NAME}
                    placeholder={LOCALIZATION.ENTER_YOUR_PREFERRED_NAME}
                    name={CREATE_USER_KEYS.PREFERRED_NAME}
                  />
                  <Email
                    label={LOCALIZATION.EMAIL}
                    placeholder={LOCALIZATION.ENTER_YOUR_EMAIL}
                    name={CREATE_USER_KEYS.EMAIL}
                    required
                  />
                  <Password
                    label={LOCALIZATION.PASSWORD}
                    placeholder={LOCALIZATION.PASSWORD}
                    name={CREATE_USER_KEYS.PASSWORD}
                    validator={{ pattern: PASSWORD_VALIDATOR_8CHA_1NUM, message: LOCALIZATION.PASSWORD_MESSAGE }}
                    required
                  />
                  <PhoneNumberWithCode
                    label={LOCALIZATION.PHONE_NUMBER}
                    defaultCountry={"au"}
                    name={CREATE_USER_KEYS.PHONE_NUMBER}
                    required
                  />
                  <DatePicker
                    label={LOCALIZATION.DATE_OF_BIRTH}
                    placeholder={LOCALIZATION.DD_MM_YYYY}
                    name={CREATE_USER_KEYS.DATE_OF_BIRTH}
                    required
                  />
                  <CheckboxItem
                    label={LOCALIZATION.FA_ENABLED}
                    name={CREATE_USER_KEYS.FA_ENABLED}
                  />
                  <Row gutter={16} className="mb-4">
                    <Col span={8}>
                      <Button
                        onClick={() => history.push(APP_URL.AUTH.LOGIN)}
                        type="white"
                      >
                        {LOCALIZATION.DISCARD}
                      </Button>
                    </Col>
                    <Col span={16} className="d-flex justify-end">
                      <Col>
                        <Button type="primary" htmlType="submit" onClick={() => new Event("submit")}>
                          + {LOCALIZATION.CREATE_ACCOUNT}
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                  <p className="d-flex justify-center mt-7">
                    {LOCALIZATION.ALREADY_HAVE_AN_ACCOUNT}?{" "}
                    <a
                      className="link-text ml-2"
                      onClick={() => history.push(APP_URL.AUTH.LOGIN)}
                    >
                      {" "}
                      {LOCALIZATION.LOG_IN}{" "}
                    </a>
                  </p>
                </Col>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default Login;
