export const API_URLS = {
  LOGIN: "/login/",
  CREATE_USER: "/users/signup/",
  GMAIL_LOGIN: "/social_accounts/google/",
  FACEBOOK_LOGIN: "/social_accounts/facebook/",
  RESET_PASSWORD: "/reset_password/",
  CONFIRM_PASSWORD: "/password_reset_confirm/",
  FA_SECURITY_CHECK: "/security_check/",
  GET_LOGGED_IN_USER: "/get_logged_in_user/",
  EDIT_PROFILE: "/users/:id",
  EVENT: "/event/",
  USERS_EMAIL: "/users/email",
  CONNECTION: "/connection/",
  PARTICIPANT_EVENT: "/event/participant/",
  ADD_GIFT_TO_WISHLIST: "/create_gifts/",
  GIFT_IN_A_WISHLIST: "/wishlist/",
  UPCOMING_BIRTHDAY: "/connection/upcoming_birthday/",
  USER_SHIPPING_INFORMATION: "/user_shipping_information/",
  NOTIFICATIONS: "/notifications/",
  MAKE_PAYMENT: "/make-payment/",
  SUGGESTED_WISHLIST: "/wishlist/",
  ENDED_EVENTS: "/event/events/:id/end-of-event-options/",
  SUGGESTED_EVENTS: "/event/events/suggested-events/2/",
  USERS_PROFILE: "/dashboard/user-profile/",
  PRODUCTS: "/dashboard/products/",
  RETAILERS: "/dashboard/retailers/",
  USERS: "/users/users_data_admin_dashboard/",
  GIFTS: "/gifts/",
  GIFT_BULK_UPDATE: "/approve_bulk_wishlist/",
  BALANCE: "/users/balance/",
  CREDIT_GIFT: "/event/send_gift_credit/",
  WITHDRAW: "/withdraw-balance/",
  ADD_PAYMENT_METHOD: "/all-payment-methods-onboarding/",
  ADD_PAYMENT_METHODS: "/add-payment-methods/",
};
