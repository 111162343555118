
import React from "react";
import { useHistory } from "react-router-dom";

// Components
import Resetbackground from "../Common/Reset&ConfirmationCommon";

// Constants
import APP_URL from "constants/ApplicationUrls";

// React Router Dom
import { useLocation } from 'react-router-dom';

// Antd
import { Button, Col, Row } from "antd";

// Localization
import LOCALIZATION from "services/LocalizationService";

function Login() {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const Base64String = queryParams.get("qrCode").replace(/ /g, '+')
    const simpleLogin = queryParams.get("simpleLogin")
    const imageUrl = `data:image/jpeg;base64,${Base64String}`;
    const handleNavigation = () => {
        const prevState = JSON.parse(localStorage.getItem("prevState"))
        if (prevState) {
            history.push(prevState); // Redirect to the requested page
        } else {
            history.push(APP_URL.USER_DASHBOARD); // Redirect to the dashboard by default
        }
        localStorage.setItem("prevState", null)
    }
    return (
        <Resetbackground>
            <div className="qr-screen-wrapper" >
                <Row >
                    <Col span={24} className="d-flex justify-center" >
                        <h3>
                            {LOCALIZATION.QR_SCREEN_TEXT}
                        </h3>
                    </Col>
                    <Col span={24} className="d-flex justify-center" >
                        <img src={imageUrl} className="qr-image" />
                    </Col>
                    <Col span={24} className="d-flex justify-center" >
                        <Col sm={24} md={12} lg={6}  >
                            {simpleLogin === "1"
                                ? <Button type="primary" onClick={() => history.push(APP_URL.AUTH.LOGIN)}> {LOCALIZATION.BACK_TO_LOGIN} </Button>
                                : <Button type="primary" onClick={handleNavigation}> {LOCALIZATION.REDIRECT_TO_DASHBOARD} </Button>

                            }
                        </Col>
                    </Col>

                </Row>

            </div>
        </Resetbackground>
    );
}

export default Login;
