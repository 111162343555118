export const CREATE_USER_KEYS={
    FAMILY_NAME:"family_name",
    FIRST_NAME:"first_name",
    PREFERRED_NAME:"preferred_name",
    EMAIL:"email",
    PHONE_NUMBER:"phone_number",
    DATE_OF_BIRTH:"date_of_birth",
    PROFILE_PHOTO:"profile_photo",
    PHOTO_TYPE:"photo_type",
    PASSWORD:"password",
    FA_ENABLED:"is_2fa_enabled"
}

export const LOGIN_USER_KEYS={
    EMAIL:"email",
    PASSWORD:"password",
}

export const RESET_PASSWORD_KEYS={
    EMAIL:"email",
    PASSWORD:"password",
}

export const SOCIAL_PLATFORM={
    GOOGLE:1,
    FACEBOOK:2,
}

export const OTP_KEYS={
    FA_CODE:"2fa_code"
}

