import React from 'react'

// Main App Logo
import appLogo from "../../../../assets/images/DopaGift Logo 4.png";

import "../../style.scss"

export default function index() {
  return (
    <div>
      <figure className="background-pic">
            <img
              src={appLogo}
              alt="logo"
              width={120}
              height={50}
            />
          </figure>
    </div>
  )
}
