/*
  Application Login Page
*/

import React from "react";
import { useHistory, useParams } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import AuthenticationBase from "..";
import Loading from "components/Loading";

// Antd
import { Form, Button, Input } from "antd";

// Icon
import { SlLock } from "react-icons/sl";

// Localization
import LOCALIZATION from "services/LocalizationService";

// Helper
import { errorNotification, successNotification } from "helpers/Notification";

// Constants
import APP_URL from "constants/ApplicationUrls";
import { API_URLS } from "constants/ApiUrl";
import STATUS_CODES from "constants/StatusCodes";
import { REDUX_STATES } from "constants/ReduxStates";

// Pattern 
import {PASSWORD_VALIDATOR_8CHA_1NUM} from "constants/Patterns"


// Actions
import { patchAction } from "store/actions/CRUDAction";

const { CONFIRM_PASSWORD, LOADING } = REDUX_STATES;

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { uid, token } = useParams();

  // Redux States

  const { [CONFIRM_PASSWORD + LOADING]: loading = false } = useSelector(
    (state) => state.Crud
  );

  const onSubmit = (data) => {
    data["uid"] = uid;
    data["token"] = token;
    dispatch(
      patchAction(API_URLS.CONFIRM_PASSWORD, data, {}, CONFIRM_PASSWORD)
    ).then(
      () => {
        successNotification(LOCALIZATION.PASSWORD_RESET_SUCCESSFULLY);
        history.push(APP_URL.AUTH.CONFIRMATION);
      },
      (e) => {
        const message = e?.response?.data?.detail
          ? e?.response?.data?.detail
          : e?.response?.status === STATUS_CODES.NOT_FOUND
          ? LOCALIZATION.ERROR_IN_RESET_PASSWORD
          : LOCALIZATION.NETWORK_ERROR;

        errorNotification(message);
      }
    );
  };
  return (
    <>
      {loading && <Loading />}
      <AuthenticationBase loading={loading}>
        <h1 className="login-text">{LOCALIZATION.NEW_PASSWORD}</h1>
        <Form layout="vertical" onFinish={onSubmit} requiredMark={LOCALIZATION.OPTIONAL} >
          <Form.Item
            className="form-group"
            name="password"
            validateTrigger={ 'onBlur' }
            label={LOCALIZATION.NEW_PASSWORD}
            rules={[
              {
                required: true,
                message: LOCALIZATION.REQUIRED,
              },
              {pattern:PASSWORD_VALIDATOR_8CHA_1NUM , message:LOCALIZATION.PASSWORD_MESSAGE},

            ]}
            hasFeedback
          >
            <Input.Password
              placeholder={LOCALIZATION.ENTER_NEW_PASSWORD}
              className="form-control"
            />
          </Form.Item>

          <Form.Item
            className="form-group"
            name="confirm_password"
            label={LOCALIZATION.CONFIRM_NEW_PASSWORD}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Password not match"));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={LOCALIZATION.CONFIRM_NEW_PASSWORD}
              className="form-control"
            />
          </Form.Item>
          <Button
            icon={<SlLock className="mr-2" />}
            type="primary"
            className="mt-5"
            htmlType="submit"
          >
            {LOCALIZATION.SAVE_NEW_PASSWORD}
          </Button>
        </Form>

        <a
          onClick={() => history.push(APP_URL.AUTH.LOGIN)}
          span={24}
          className=" back-to-login d-flex justify-center mt-5 "
        >
          {" "}
          {LOCALIZATION.DISCARD_BACK_TO_LOGIN}{" "}
        </a>
      </AuthenticationBase>
    </>
  );
}

export default Login;
