/*
    General Helper Class
*/

// example: https://bithacker.dev/javascript-object-multi-property-sort

import StorageService from "services/StorageService";

import LOCALIZATION from "services/LocalizationService";

// Date Formatter
import { format, parse, isAfter, isToday, addYears, differenceInDays, differenceInWeeks } from 'date-fns';


export const isSuperAdmin = () => {
  const token = StorageService.instance.getAccessToken() || {}
  const isSuperAdmin = parseJwt(token)?.user?.is_superuser || ""
  return isSuperAdmin
}

export const getName = () => {
  const token = StorageService.instance.getAccessToken() || {};
  const { first_name, family_name } = parseJwt(token)?.user || {};

  if (!!first_name && !!family_name) {
    return `${first_name} ${family_name}`;
  } else if (!!first_name) {
    return first_name;
  } else {
    return family_name || LOCALIZATION.USER;
  }
};

export const getUserId = () => {
  const token = StorageService.instance.getAccessToken() || {};
  return parseJwt(token)?.user_id;
};

export const getEmail = () => {
  const token = StorageService.instance.getAccessToken() || {};
  return parseJwt(token)?.user?.email;
};

export const parseJwt = (token) => {
  var base64Url = token?.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const disabledFutureDates = (current) => {
  return current && current.valueOf() > Date.now();
};

// Sort an array
export function sortArray(array = [], sortBy) {
  return (
    array &&
    array.sort(function (a, b) {
      let i = 0,
        result = 0;
      while (i < sortBy.length && result === 0) {
        result =
          sortBy[i].direction *
          (a[sortBy[i]?.prop]?.toString() < b[sortBy[i]?.prop]?.toString()
            ? -1
            : a[sortBy[i]?.prop]?.toString() > b[sortBy[i]?.prop]?.toString()
              ? 1
              : 0);
        i++;
      }
      return result;
    })
  );
}

export function truncateString(str, n) {
  if (str?.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
}

export function camalize(str) {
  const words = str.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1).toLowerCase();
  }

  return words.join(" ");
}

// Get Int value of given value
export function getIntVal(value) {
  return parseInt(value || 0);
}

// Add Thousand Separator in value
export function thousandSeprator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const toBase64 = async (file, validateImage) => {
  const action = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  const base64String = await action;
  let result = !!validateImage ? base64String : base64String?.split(",")?.[1];
  return result;
};

// Format date this 2023-08-31T00:00:00Z to dd mmmm yyyy  
export const formatDateTime = (inputDate) => {
  if (!!inputDate) {
    const parsedDate = new Date(inputDate);

    // Format the date using date-fns
    const formattedDate = format(parsedDate, 'dd MMM yyyy');

    return formattedDate;
  }

  return ''; // Return an empty string if inputDate is falsy
};

// Format date this 1/8/2023 to 1 august 2023
export const formatDate = (inputDate) => {
  if (!!inputDate) {
    const [day, month, year] = inputDate.split('/');
    const parsedDate = new Date(`${year}-${month}-${day}`);

    // Format the date
    const formattedDate = format(parsedDate, 'dd MMM yyyy');
    return formattedDate;
  }
}

// Format time to 1/8/2023 to 1 aug
export const formatDatetoDateMonth = (inputDate) => {
  if (!!inputDate) {
    const [day, month, year] = inputDate.split('/');
    const parsedDate = new Date(`${year}-${month}-${day}`);

    // Format the date to display only the first three characters of the month
    const formattedDate = format(parsedDate, 'dd MMM yyyy').slice(0, 7);
    return formattedDate;
  }
}

export const calculateUpcomingBirthdayDifference = (birthday) => {
  const today = new Date();
  const [originalYear, month, day] = birthday.split("-");

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Creating a new Date object with the current year, month, and day
  const birthdate = new Date(currentYear, parseInt(month, 10) - 1, parseInt(day, 10));

  // Check if today is the same as the birthdate
  if (isToday(birthdate)) {
    return 'Today';
  }

  // Calculate next birthday by adding a year if today is after the birthdate
  const nextBirthday = isAfter(today, birthdate) ? addYears(birthdate, 1) : birthdate;

  // Calculate the difference in days
  const differenceInDaysValue = differenceInDays(nextBirthday, today) + 1;

  if (differenceInDaysValue >= 7 && differenceInDaysValue < 28) {
    // If more than 7 days but less than 28 days, show weeks
    const differenceInWeeksValue = differenceInWeeks(nextBirthday, today) + 1;
    return `${differenceInWeeksValue} ${differenceInWeeksValue === 1 ? 'week' : 'weeks'}`;
  } else if (differenceInDaysValue >= 28) {
    // If more than 4 weeks, show months
    const differenceInMonthsValue = Math.ceil(differenceInDaysValue / 30); // Approximating months as 30 days
    return `${differenceInMonthsValue} ${differenceInMonthsValue === 1 ? 'month' : 'months'}`;
  } else {
    // Otherwise, show days
    return `${differenceInDaysValue} ${differenceInDaysValue === 1 ? 'day' : 'days'}`;
  }
};


export function getFiltersCount(filterObj = {}) {
  let count = 0;
  filterObj &&
    // eslint-disable-next-line array-callback-return
    Object.keys(filterObj)?.map((key) => {
      const value = filterObj?.[key];
      const isArray = Array.isArray(value);
      if ((!isArray && !!value) || (!!isArray && !!value?.length > 0)) {
        count++;
      }
    });
  return count;
}

export function parseGeneralErrorMessage(object) {
  let message = "";
  for (const property in object) {
    message += object[property]?.toString() + ", ";
  }
  return message.substring(0, message.length - 2);
}

export function isObjectEmpty(obj) {
  return (
    !!obj &&
    !!!Object.keys(obj)
      ?.map((key) => !!obj?.[key])
      ?.filter((i) => i !== false)?.length
  );
}