import React, { useState, useRef } from 'react';
import { AutoComplete, Input, Form, Spin } from 'antd';
import debounce from 'lodash/debounce';

// Localization
import LOCALIZATION from '../../../../services/LocalizationService';

const InputSuggesstion = ({ name, inputKeys, form, label, notwrapInForm, validateTrigger, validator, required, getUsersEmail, mapApiResponse, onChange, disabled }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [allRecordsLoaded, setAllRecordsLoaded] = useState();

  const [inputValue, setInputValue] = useState('');
  const scrollContainerRef = useRef(null);
  const debouncedSearch = debounce((value) => {
    handleSearch(value);
  }, 300);

  const handleSearch = async (value, currentPage = 1) => {
    setLoading(true);

    // Simulate an API call delay
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Simulate API response with suggestions
    const apiResponse = await getUsersEmail(value, currentPage) || [];

    setAllRecordsLoaded(apiResponse.next);

    const newOptions = apiResponse.results.map(mapApiResponse);

    if (currentPage === 1) {
      setOptions(newOptions);
    } else {
      setOptions((prevOptions) => [...prevOptions, ...newOptions]);
    }

    setLoading(false);
  };

  const handleInputSearch = (value) => {
    setPage(1);
    setInputValue(value);
    setLoading(true);
    debouncedSearch(value, 1);
  };

  const handleSelect = (value) => {
    form.setFieldValue(inputKeys, value)
    setInputValue(value);
  };

  const handleScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    if (
      scrollContainer.scrollHeight - scrollContainer.scrollTop ===
      scrollContainer.clientHeight
    ) {
      if (allRecordsLoaded !== null) {
        const nextPage = page + 1;
        handleSearch(inputValue, nextPage);
        setPage(nextPage);
      }
    }
  };

  // Rules
  let rules = [
    { required: required, message: LOCALIZATION.REQUIRED },
    { type: 'email', message: LOCALIZATION.INVALID_EMAIL },
    // Add email validation rule here if needed
  ];

  // Custom Validations
  !!validator && rules.push(validator);

  const InputSuggesstion = (
    <div ref={scrollContainerRef} style={{ maxHeight: '200px', overflowY: 'auto' }}>
      <AutoComplete
        options={options.map((option) => ({ value: option.value }))}
        onSelect={handleSelect}
        onSearch={handleInputSearch}
        notFoundContent={loading ? <div style={{ textAlign: 'center', padding: '8px' }}>
                                      <Spin size="small" />
                                    </div> : 'No data found'}
        onPopupScroll={handleScroll}
        disabled={disabled}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {loading && options.length > 0 && (
              <div style={{ textAlign: 'center', padding: '8px' }}>
                <Spin size="small" />
              </div>
            )}
          </div>
        )}
      >
        <Input
          placeholder="Type an email..."
          value={inputValue}
          loading={loading}
          onChange={onChange}
        />
      </AutoComplete>
    </div>
  )

  return (
    !!notwrapInForm ? (
      InputSuggesstion
    ) : (
      <Form.Item
        name={name}
        label={label}
        validateTrigger={validateTrigger || 'onBlur'}
        rules={rules}
      >
        {InputSuggesstion}
      </Form.Item>
    )
  );
};

export default InputSuggesstion;
