import { SECTIONS } from "./MenuSections";

const {
  PROFILE,
  EVENT,
  ADMIN_PROFILE,
  CONNECTION,
  DASHBOARD,
  WISHLIST,
  HISTORY,
  USER,
} = SECTIONS;

const URL = {
  USER_DASHBOARD: `/${DASHBOARD}/user`, // User Dashboard
  SUPER_ADMIN_DASHBOARD: `/${DASHBOARD}/superAdmin`, // Super Admin Dashboard
  ALL_USERS: `/${DASHBOARD}/superAdmin/allusers`, // All Users
  ALL_PRODUCTS: `/${DASHBOARD}/superAdmin/allproducts`, // All Products
  ALL_RETAILERS: `/${DASHBOARD}/superAdmin/allretailers`, // All Retailers

  PROFILE: `/${PROFILE}`, // Profile
  EDIT_PROFILE: `/${PROFILE}/edit`, // Edit Profile
  SHIPPING_INFORMATION: `/${PROFILE}/shippingInformation`, // Shipping Information
  FINANCIAL_INFORMATION: `/${PROFILE}/financialInformation`, // Financial Information

  ADMIN_EDIT_PROFILE: `/${ADMIN_PROFILE}/edit`, // AdminEdit

  CURRENT_EVENT: `/${EVENT}/current`, // Current Event
  CREATE_EVENT: `/${EVENT}/create`, // Create Event
  INDIVIDUAL_EVENT: `/${EVENT}/individual/:id`, // Individual Event
  ALL_ORGANISER_EVENT: `/${EVENT}/allOrganiser/`, // All Organiser Event
  ALL_PARTICIPANT_EVENT: `/${EVENT}/allParticipant/`, // All Participant Event
  ALL_SUGGESTED_EVENT: `/${EVENT}/allSuggested/`, // All Suggested Event
  ENDED_EVENT: `/${EVENT}/end/:id`, // Ended Event

  VIEW_CONNECTION: `/${CONNECTION}/view`, // View All Connection
  INDIVIDUAL_CONNECTION: `/${CONNECTION}/individual/:id`, // Individual Connection

  ADD_GIFT: `/${WISHLIST}/addGift`, // Add Gifts
  RECIPIENT_WISHLIST: `/${WISHLIST}/recipientWishlist/:id`, // Recipient Wishlist

  GIFT_DETAIL: `/${HISTORY}/giftDetail`, // Gift Detail

  USER_LIST: `/${USER}/list`, // User List

  ACCEPT_REJECT_WISHLIST: `/${WISHLIST}/list`, // User List

  NOTIFICATION: `/notification`, //Notification List

  AUTH: {
    LOGIN: "/login", // Login
    SIGNUP: "/signup", // signup
    RESET_PASSWORD: "/resetPassword", // Reset Password
    CONFIRM_PASSWORD: "/confirmPassword/:uid/:token", // Confirm Password
    CONFIRMATION: "/confirmation", // Confirmation
    QR_CODE: "/qrcode", // QR Code
    OTP: "/otp",
  },
  ERROR: {
    PAGE404: "/page404",
    PAGE500: "/page500",
  },
};

export default URL;
