/*
  Application Login Page
*/

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import AuthenticationBase from "..";

// Constants
import APP_URL from "constants/ApplicationUrls";
import STATUS_CODES from "constants/StatusCodes";
import { API_URLS } from "constants/ApiUrl";
import { REDUX_STATES } from "constants/ReduxStates"

// Actions
import { login } from "store/actions/AuthAction";
import { postAction } from "store/actions/CRUDAction";


// Antd
import { Form, Button, Row, Col } from "antd";

// Component
import { Email, Password } from "../../Common/FormElements";
import SocialLogin from "./SocialLogin";
import { LOGIN_USER_KEYS } from "../Constant";

// Localization
import LOCALIZATION from "services/LocalizationService";

// Helper
import { errorNotification, successNotification } from "helpers/Notification";
import { isSuperAdmin } from "helpers/GeneralHelper";

const { SECURITY_CHECK, LOADING } = REDUX_STATES

function Login() {

  useEffect(() => {
    if (location.state && location.state.from) {
      localStorage.setItem("prevState", JSON.stringify(location.state.from.pathname));
    }
  })

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Redux States
  const loading = useSelector((state) => state?.Auth?.loading);
  const { [SECURITY_CHECK + LOADING]: securityLoading = false } = useSelector((state) => state.Crud)



  const onSubmit = (data) => {
    const email = data?.email
    dispatch(postAction(API_URLS.FA_SECURITY_CHECK, { email }, {}, SECURITY_CHECK))
      .then(res => {
        if (res?.data?.security_status === 0) {
          errorNotification(LOCALIZATION.ACCOUNT_NOT_EXIST);
        }
        else if (res?.data?.security_status === 2) {
          simpleLogin(data)
        }
        else {
          history.push(APP_URL.AUTH.OTP + `?email=${data.email}&password=${data.password} `)
        }
      },
        (e) => {
          const message = e?.response?.data?.detail
            ? e?.response?.data?.detail
            : e?.response?.status === STATUS_CODES.NOT_FOUND
              ? LOCALIZATION.SCURITY_CHECK_NOT_FOUND
              : LOCALIZATION.NETWORK_ERROR;
          errorNotification(message);
        })

  };

  const simpleLogin = (data) => {
    const prevState = JSON.parse(localStorage.getItem("prevState"))
    dispatch(login(API_URLS.LOGIN, data)).then(
      () => {
        successNotification(LOCALIZATION.LOG_IN_SUCCESSFULLY);
        if (prevState) {
          history.push(prevState); // Redirect to the requested page
        } else {
          history.push( !!isSuperAdmin()? APP_URL.SUPER_ADMIN_DASHBOARD:APP_URL.USER_DASHBOARD); // Redirect to the dashboard by default
        }
        localStorage.setItem("prevState", null)
      },
      (e) => {
        const message = e?.response?.data?.detail
          ? e?.response?.data?.detail
          : e?.response?.status === STATUS_CODES.NOT_FOUND
            ? LOCALIZATION.INVALID_EMAIL_OR_PASSWORD
            : LOCALIZATION.NETWORK_ERROR;
        errorNotification(message);
      }
    );
  }

  return (
    <AuthenticationBase loading={loading || securityLoading}>
      <h1 className="login-text">{LOCALIZATION.LOGIN}</h1>
      <Form layout="vertical" onFinish={onSubmit} requiredMark={LOCALIZATION.OPTIONAL}>
        <Col>
          <Email
            label={LOCALIZATION.EMAIL}
            placeholder={LOCALIZATION.ENTER_YOUR_EMAIL}
            name={LOGIN_USER_KEYS.EMAIL}
            required
          />
          <Row>
            <Col span={12}>
              <p> {LOCALIZATION.PASSWORD} </p>
            </Col>
            <Col span={12} className="d-flex justify-end align-center">
              <a
                className="forget-password-link"
                onClick={() => history.push(APP_URL.AUTH.RESET_PASSWORD)}
              >
                {" "}
                {LOCALIZATION.FORGOT_PASSWORD} ?{" "}
              </a>
            </Col>
          </Row>
          <Password
            placeholder={LOCALIZATION.PASSWORD}
            name={LOGIN_USER_KEYS.PASSWORD}
            required
          />
        </Col>
        <Button type="primary" className="mt-3" htmlType="submit">
          <span class="icon-send"></span>
          {LOCALIZATION.LOGIN}
        </Button>
      </Form>

      <SocialLogin />
      <p className="d-flex justify-center mt-3">
        {LOCALIZATION.NO_LOGIN_YET}?{" "}
        <a
          className="link-text ml-2"
          onClick={() => history.push(APP_URL.AUTH.SIGNUP)}
        >
          {" "}
          {LOCALIZATION.CREATE_ACCCOUNT}{" "}
        </a>
      </p>
    </AuthenticationBase>
  );
}

export default Login;
