/*
  Input Password
*/

import React,{useState,useEffect} from 'react';

// Antd
import { Form } from 'antd';

// Phone input with code/flag
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Localization
import LOCALIZATION from '../../../../services/LocalizationService';


// Style
import './style.scss';

function PhoneNumberWithCode({ 
  name, label, 
  placeholder, defaultValue, 
  onChange, disabled, required,
  onBlur, validateTrigger,
  validator, notwrapInForm,
  defaultCountry ,editStatus}) {
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    // Get user's location using HTML5 Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          try {
            // Fetch country information based on user's IP address
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
            const data = await response.json();

            if (response.ok) {
              const countryCode = data.address.country_code;
              setCountryCode(countryCode)
            } else {
              console.error('Error fetching country code:', data);
            }
          } catch (error) {
            console.error('Error fetching country code:', error);
          }
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available.');
    }
  }, []);

  // Rules
  let rules = [
    { required: required, message: LOCALIZATION.REQUIRED  },
  ];

  // Custom Validation
  !!validator && rules.push( validator );

  // Input Email
  const PhoneNumberWithCode = (
    <PhoneInput country={ !editStatus? countryCode || defaultCountry:null} enableSearch={true} onChange={onChange} placeholder={placeholder} disabled={disabled} />
  );
  
  return(
    !!notwrapInForm?
      <>
        { PhoneNumberWithCode }
      </>:
      <Form.Item
        name={ name }
        label={ label }
        validateTrigger={ validateTrigger || 'onBlur' }
        rules={ rules }
      >
        { PhoneNumberWithCode }
      </Form.Item>
  );
}

export default PhoneNumberWithCode;