/*
  Application Login Page
*/

import React from "react";

// Component
import Background from "../../Common/BackgroundView";

function Layout({ children }) {
  return (
    <div className="reset-wrapper">
      <Background />
      <figure class="overlay-full-width">{children}</figure>
    </div>
  );
}

export default Layout;
