export const REDUX_STATES = {
  DEFAULT_KEY: "listing" + new Date().getTime(),
  DEFAULT_DROPDOWN_KEY: "dropdown" + new Date().getTime(),
  DEFAULT_POST_KEY: "post" + new Date().getTime(),
  DEFAULT_PUT_KEY: "put" + new Date().getTime(),
  DEFAULT_PATCH_KEY: "patch" + new Date().getTime(),
  DEFAULT_DELETE_KEY: "delete" + new Date().getTime(),
  DEFAULT_SELECTED_KEY: "selected" + new Date().getTime(),
  LOADING: "_loading",
  ERROR: "_error",
  RESPONSE: "_response",
  CREATE_USER: "createuser",
  RESET_PASSWORD: "resetpassword",
  RESEND_EMAIL: "resendemail",
  CONFIRM_PASSWORD: "Confirmpassword",
  SECURITY_CHECK: "securitycheck",
  PROFILE: "profile",
  EDIT_PROFILE: "editprofile",
  CREATE_EVENT: "createevent",
  USERS_EMAIL: "usersemail",
  CONNECTION: "connection",
  INDIVIDUAL_CONNECTION: "individualconnection",
  ORGANISER_EVENT: "organiserevent",
  ALL_ORGANISER_EVENT: "allorganisertevent",
  PARTICIPANT_EVENT: "participantevent",
  ALL_PARTICIPANT_EVENT: "allparticipantevent",
  SUGGESTED_EVENT: "suggestedevent",
  ALL_SUGGESTED_EVENT: "allsuggestedevent",
  EVENT_DETAIL: "eventdetail",
  ACCEPT_EVENT: "acceptevent",
  REJECT_EVENT: "rejectevent",
  OWN_GIFT_EVENT: "owngiftevent",
  ADD_GIFT_TO_WISHLIST: "addgifttowishlist",
  GIFT_IN_A_WISHLIST: "giftinawishlist",
  UPCOMING_EVENTS: "upcomingevent",
  WISHLIST: "wishlist",
  UPCOMING_BIRTHDAY: "upcomingbirthday",
  SHIPPING_INFORMATION: "shippinginformation",
  RECIPIENT_WISHLIST: "recipientwishlist",
  NOTIFICATIONS: "Notifications",
  MAKE_PAYMENT: "makepayment",
  SUGGESTED_WISHLIST: "suggestedwishlist",
  SUGGESTED_EVENTS: "suggestedevents",
  USERS_PROFILE: "usersprofile",
  PRODUCTS: "products",
  RETAILERS: "retailers",
  USERS: "users",
  GIFTS: "gifts",
  GIFT: "gift",
  OPEN_EVENTS: "openevents",
  GIFT_STATUS: "giftstatus",
  USER_BALANCE: "userbalance",
  ALL_NOTIFICATION: "allnotification",
  CREDIT_GIFT: "creditgift",
  WITHDRAW: "withdraw",
  PAYMENTS_METHOD: "paymentMethod",
};
