/*
  Application Login Page
*/

import React from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import Resetbackground from "../Common/Reset&ConfirmationCommon";
import Loading from "components/Loading";

// Helper
import { errorNotification, successNotification } from "helpers/Notification";

// Constants
import APP_URL from "constants/ApplicationUrls";
import { API_URLS } from "constants/ApiUrl";
import STATUS_CODES from "constants/StatusCodes";
import { REDUX_STATES } from "constants/ReduxStates";

// Actions
import { postAction } from "store/actions/CRUDAction";

// Antd
import { Button, Col } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

// Localization
import LOCALIZATION from "services/LocalizationService";


const { RESEND_EMAIL, LOADING } = REDUX_STATES;

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  // Redux States
  const { [RESEND_EMAIL + LOADING]: loading = false } = useSelector(
    (state) => state.Crud
  );

  const onSubmit = () => {

    const data = {
      email: localStorage.getItem('email')
    };
    dispatch(
      postAction(API_URLS.RESET_PASSWORD, data, {}, RESEND_EMAIL)
    ).then(
      () => {
        successNotification(LOCALIZATION.SENT_EMAIL_SUCCESSFULLY);
      },
      (e) => {
        const message = e?.response?.data
          ? e?.response?.data
          : e?.response?.status === STATUS_CODES.NOT_FOUND
            ? LOCALIZATION.ERROR_IN_ENDING_EMAIL
            : LOCALIZATION.NETWORK_ERROR;

        errorNotification(message);
      }
    );
  }
  return (
    <Resetbackground>
      {loading && <Loading />}
      <CheckCircleOutlined className="icon-confirmation" />
      <p className="login-text"> Email Sent! </p>
      <div className="center-wrap">
        <p>
          {LOCALIZATION.CONFIRMATION_SCREEN_TEXT} <br />
          {LOCALIZATION.CONFIRMATION_SCREEN_TEXT2}
        </p>
      </div>
      <Col className="mt-1">
        <Button
          type="primary"
          //   icon={<GoogleOutlined />}
          onClick={() => history.push(APP_URL.AUTH.LOGIN)}
        >
          {LOCALIZATION.BACK_TO_LOGIN}
        </Button>
        <a
          onClick={onSubmit}
          span={24}
          className=" back-to-login d-flex justify-center mt-5 "
        >
          {LOCALIZATION.RESEND_EMAIL}
        </a>
      </Col>
    </Resetbackground>
  );
}

export default Login;
