export const SECTIONS = {
 DASHBOARD:"dashboard",
 PROFILE:"profile",
 EVENT:"event",
 CONNECTION:"connection",
 WISHLIST:"wishlist",
 HISTORY:"history",
 USER:"user",
 WISHLIST_MANAGEMENT:"wishlistmanagement",
 ADMIN_PROFILE:"adminprofile"
};