/*
  Application Login Page
*/

import React from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import Resetbackground from "../Common/Reset&ConfirmationCommon";
import { Email } from "../../Common/FormElements";
import { RESET_PASSWORD_KEYS } from "../Constant";
import Loading from "components/Loading";

// Antd
import { Form, Button, Row, Col } from "antd";

// Icon
import { FiSend } from "react-icons/fi";

// Localization
import LOCALIZATION from "services/LocalizationService";

// Helper
import { errorNotification, successNotification } from "helpers/Notification";
import { parseGeneralErrorMessage } from "helpers/GeneralHelper";


// Constants
import APP_URL from "constants/ApplicationUrls";
import STATUS_CODES from "constants/StatusCodes";
import { REDUX_STATES } from "constants/ReduxStates";
import { API_URLS } from "constants/ApiUrl";

// Actions
import { postAction } from "store/actions/CRUDAction";

const { RESET_PASSWORD, LOADING } = REDUX_STATES;

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  // Redux States
  const { [RESET_PASSWORD + LOADING]: loading = false } = useSelector(
    (state) => state.Crud
  );

  const onSubmit = (data) => {
    dispatch(
      postAction(API_URLS.RESET_PASSWORD, data, {}, RESET_PASSWORD)
    ).then(
      () => {
        localStorage.setItem('email', data?.email);
        successNotification(LOCALIZATION.SENT_EMAIL_SUCCESSFULLY);
        history.push(APP_URL.AUTH.LOGIN);
      },
      (e) => {
        const message = parseGeneralErrorMessage(e.response.data)
          ? e?.response?.data
          : e?.response?.status === STATUS_CODES.NOT_FOUND
          ? LOCALIZATION.ERROR_IN_ENDING_EMAIL
          : LOCALIZATION.NETWORK_ERROR;

        errorNotification(message);
      }
    );
  };

  return (
    <>
      <Resetbackground>
        {loading && <Loading />}
        <h1 className="login-text">{LOCALIZATION.PASSWORD_RESET}</h1>
        <div className="login-form-wrapper">
          <Form layout="vertical" onFinish={onSubmit} requiredMark={LOCALIZATION.OPTIONAL}>
            <Row justify="center">
              <Col xs={22} sm={20} md={12} lg={10} xl={10}>
                <Email
                  label={LOCALIZATION.EMAIL}
                  placeholder={LOCALIZATION.ENTER_YOUR_EMAIL}
                  name={RESET_PASSWORD_KEYS.EMAIL}
                  required
                />

                <Button
                  icon={<FiSend className="mr-2" />}
                  type="primary"
                  className="mt-5"
                  htmlType="submit"
                >
                  {LOCALIZATION.SEND_RESET_PASSWORD_LINK}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        <a
          onClick={() => history.push(APP_URL.AUTH.LOGIN)}
          span={24}
          className="back-to-login d-flex justify-center mt-5"
        >
          {LOCALIZATION.DISCARD}
        </a>
      </Resetbackground>
    </>
  );
}

export default Login;
