/*
  Actual application starts from this file
*/

import React from 'react';
import { 
  BrowserRouter, 
  Switch, 
  Route 
} from 'react-router-dom'; //https://css-tricks.com/the-hooks-of-react-router/
import Loadable from 'react-loadable';

// Antd
import { notification } from 'antd';

// Constants
import APP_URL from 'constants/ApplicationUrls';
import { NOTIFICATION_DURATION } from 'constants/General';

// Components
import PrivateRoute from 'components/PrivateRoute';
import Loading from 'components/Loading';

// Auth Components
import Login from 'components/Authentication/Login';
import Signup from "./components/Authentication/Signup";
import Reset_password from "./components/Authentication/ResetPassword";
import Confirm_password from "./components/Authentication/ConfirmPassword";
import Confirmation from "./components/Authentication/Confirmation";
import QrCode from "./components/Authentication/QRCode";
import OTP from "./components/Authentication/Otp";



// Localization
import LOCALIZATION from 'services/LocalizationService';

// Styling
import './assets/sass/style.scss';

// Package.json
const packageJson = require('../package.json');

// Notification Configuration for whole application
notification.config({
  placement: 'topRight',
  duration: NOTIFICATION_DURATION,
  rtl: false
});

// Base Layout
const BaseLayout = Loadable({
  loader: () => import('components/BaseLayout'),
  loading: Loading
});

function App() {

  return (
    <div className={`App`}>
      <BrowserRouter basename={ packageJson?.homepage || '' }>
        <Switch>
          {/* 
            Open Routes Here 
          */}

          {/* Login */}
          <Route
            path={ APP_URL.AUTH.LOGIN }
            name={ LOCALIZATION.LOGIN }
            component={ Login }
          />
          <Route
            path={ APP_URL.AUTH.SIGNUP }
            name= {LOCALIZATION.SIGNUP}
            component={ Signup }
          />
          <Route
            path={ APP_URL.AUTH.RESET_PASSWORD }
            name= {LOCALIZATION.RESET_PASSWORD}
            component={ Reset_password }
          />
          <Route
            path={ APP_URL.AUTH.CONFIRM_PASSWORD }
            name= { LOCALIZATION.CONFIRM_PASSWORD }
            component={ Confirm_password }
          />
          <Route
            path={ APP_URL.AUTH.CONFIRMATION }
            name= { LOCALIZATION.CONFIRMATION }
            component={ Confirmation }
          />
          <Route
            path={ APP_URL.AUTH.QR_CODE }
            name= { LOCALIZATION.QR_CODE}
            component={ QrCode }
          />\
           <Route
            path={ APP_URL.AUTH.OTP }
            name= { LOCALIZATION.OTP}
            component={ OTP }
          />

          {/* Restricted Routes Here */}
          <PrivateRoute 
            component={ BaseLayout } 
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;